import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import "./Css.css";
import { Button } from "../../components/Navbar/Navbar";
import i1 from "./1.png";
import i2 from "./2.png";
import bg from "./bg.png";
import card from "./card.png";
import { useWallet } from "use-wallet";
import Web3 from "web3";
import "./Css.css";
import PresaleABI from "../../config/Presale.json";
import config from "../../config";



import { MerkleTree } from "merkletreejs";
import keccak256 from "keccak256";





const Sec = styled.section`
  min-height: 100vh;
  width: 100%;
  display: flex;
  // flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #000;
  background-image: url(${bg});
  background-size: 100% 100%;
  background-position: center center;
  background-repeat: no-repeat;

  padding: 8rem 0 3rem 0;

  @media only screen and (max-width: 768px) {
    min-height: 100vh;
    width: 100%;

    flex-direction: column;
    justify-content: space-around;
  }
`;

const Width = styled.div`
  width: 1100px;
  display: flex;
  // flex-direction: column;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 1400px) {
    width: 95%;
  }
  @media only screen and (max-width: 1100px) {
    flex-direction: column;
  }
`;

const Img = styled(motion.div)`
  width: 30rem;
  border-radius: 1rem;
  @media only screen and (max-width: 768px) {
    margin-right: 0;
    width: 100%;
    padding: 0 1rem;
  }
`;

const T = styled.p`
  color: #fff;
  font-weight: 500;
  font-size: 1.2rem;
  margin: 0 0 0.5rem 0;
  // text-align: center;
  font-family: "Space Grotesk", sans-serif;

  @media only screen and (max-width: 768px) {
    font-weight: 510;
    font-size: 1rem;
  }
`;

const H = styled.h1`
  color: #fff;
  font-size: 2rem;
  // font-weight: light;
  // margin-bottom: 2rem;
  text-align: left !important;
  @media only screen and (max-width: 768px) {
    font-weight: 510;
    font-size: 1.2rem;
    font-weight: bold;
  }
`;

const Left = styled.div`
  width: 35%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: rgba(2, 169, 92, 1);

  //   @media only screen and (max-width: 1100px) {
  //     width: 50%;
  // }

  @media only screen and (max-width: 998px) {
    width: 100%;
    padding: 1rem 0;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  //
  align-items: center;
  justify-content: space-between;
  //width: 60%;
  margin: 0 0 2rem 0;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  min-height: 50rem;

  background-image: url(${card});
  background-size: 100% 100%;

  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;

  @media only screen and (max-width: 768px) {
    width: 98%;
  }
`;

const Line = styled.div`
  width: 20rem;
  height: 1px;
  background: rgba(0, 0, 0, 0.2);
`;

const Flex = styled.div`
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 4rem 0 0 0;

  @media only screen and (max-width: 1150px) {
    flex-direction: column;
  }
`;
const Button2 = styled(Button)`
  margin: 2rem 0 0 2rem;

  @media only screen and (max-width: 768px) {
    margin: 2rem 0;
  }
`;
const Sbox = styled.div`
  width: 45%;

  @media only screen and (max-width: 1150px) {
    width: 90%;
  }
`;
const Sbox2 = styled.div`
  width: 45%;

  @media only screen and (max-width: 1150px) {
    width: 90% !important;
    margin: 2rem 0 0 0;
  }
`;

const WHITELISTED_ADDRESSES = [
  "0xfbde2113c3552eb60d0a5ea73396196b728aabcc","0xeb1a44e9e2860fe257ac4cb7d64fd8adf788d9a4","0x2b1dca6e11e81abade865d8081ba0d4d3520be5d","0x17d7ed28a022237cb40783b676c91d2ea85e5350","0x27142b581867ad7f19e3bff1d0a6a03ff9216925","0xad90e5c3ede575361f433c15e471b5e4f042d72a","0x758348f346db10d93b602e6092d827daf5d2bed6","0xbbed9f0c5f578abb4c531ee816d106ac7f6ba08c","0xc7095d7cccbdc0779c0e4d0d4dae66e4f0ab07a5","0x1580f767211b79db748238014093f78bd29d8b6c","0x41df7be8ff245e4fee872c29bf8dbc1ba61e095e","0xaf2358e98683265cbd3a48509123d390ddf54534","0x91db87e43a6e07e0daea3b604213d69cca665805","0x0651496da33b23781fc50e430183bbb33b60b5a3","0xd4800db9e82c59468ed4ba97e28bd55bda031e81","0xa6cf10bdb5a20c58b53dd3de55c4e75dc284b99e","0x56b3c7bcbf841cb53941afe25ad993711910fe7b","0x8390bfe2f1a673747b1725b39d32d83af0910412","0x6d68995142d57bc9079e7f36d823a600506d66a7","0xb07740a179074fcabb45eab041bb12e7e2fed73f","0x43b7b943595dd87d72bb878da8d3a7888acee9ec","0x8aa8eb930a2e0bce93d2417f797fda170549012a","0xAE637BF6cAD3F3bfabc3215964b0af84cEAE2984","0x388e8c2ff281f87958839c7a03d1eadad79d997e","0xe9c48206129f051ff4a17ca651b2e077b1ff5070"

];


const getMerkleTree = () => {
    const leafNodes = WHITELISTED_ADDRESSES.map((addr) => keccak256(addr));
    const merkleTree = new MerkleTree(leafNodes, keccak256, { sortPairs: true });
    return merkleTree;
  };
  
  export const getRootHash = () => {
    const merkleTree = getMerkleTree();
    const rootHash = merkleTree.getRoot();
    return rootHash.toString("hex");
  };
  

  console.log("sasa",getRootHash())
  export const getMerkleProofHash = (address) => {
    const merkleTree = getMerkleTree();
    const proof = merkleTree.getHexProof(keccak256(address));
    return proof;
  };
  
const Page6a = () => {
  const wallet = useWallet();
  const [status, setStatus] = useState();

  const [price, setPrice] = useState(0);
  const [myContribution, setMyContribution] = useState(0);
  const [myClaimableBalance, setMyClaimableBalance] = useState(0);
  const [isParticipateEnabled, setIsParticipateEnabled] = useState(false);
  const [isClaimEnabled, setClaimEnabled] = useState(false);
  const [contract, setContract] = useState();
  const [ethValue, setETHValue] = useState(0);
  const [moonValue, setMoonValue] = useState(0);
  const [maxBuyLimit, setMaxBuyLimit] = useState(0);


  const changeNetwork = async () => {
    if (window.ethereum) {
      try {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: Web3.utils.toHex(1) }],
        });
      } catch (error) {
        console.error(error);
      }
    }
  };

  function toFixed(x) {
    if (Math.abs(x) < 1.0) {
      var e = parseInt(x.toString().split("e-")[1]);
      if (e) {
        x *= Math.pow(10, e - 1);
        x = "0." + new Array(e).join("0") + x.toString().substring(2);
      }
    } else {
      var e = parseInt(x.toString().split("+")[1]);
      if (e > 20) {
        e -= 20;
        x /= Math.pow(10, e);
        x += new Array(e + 1).join("0");
      }
    }
    return x;
  }
  const handleParticipate = async () => {
    await changeNetwork();



    if(Number(moonValue)+ Number(myClaimableBalance) > Number(maxBuyLimit)){
      
        alert("Can't Buy more than max limit");
        return 
    }

    
    const web3 = new Web3(Web3.givenProvider)

    try{
        const _contract = new web3.eth.Contract(PresaleABI, config.PRESALE_ADDRESS);
        const merklehsh = getMerkleProofHash(wallet.account)
        await _contract.methods.participate(merklehsh).send({
            from:wallet.account,
            value:Web3.utils.toWei(ethValue.toString())
        })
    }catch(er){
        alert(er)
    }
  };

  const handleClaim = async () => {
    await changeNetwork();

    const web3 = new Web3(Web3.givenProvider)

    try{
        const _contract = new web3.eth.Contract(PresaleABI, config.PRESALE_ADDRESS);
        await _contract.methods.claimToken().send({
            from:wallet.account,
        })
    }catch(er){
        alert(er)
    }
  };

  const getWeb3 = async () => {
    let web3;
    if (Web3.givenProvider) {
      web3 = new Web3(Web3.givenProvider);
      const chainId = await web3.eth.getChainId();
      if (chainId != 1) {
        web3 = null;
      }
    }
    if (!web3) {
      web3 = new Web3(config.RPC);
    }
    return web3;
  };

  const loadContract = async () => {
    const web3 = await getWeb3();
    let _contract = new web3.eth.Contract(PresaleABI, config.PRESALE_ADDRESS);
    const tokenPrice = await _contract.methods.tokenPrice().call();
    setPrice(Number(Web3.utils.fromWei(tokenPrice)));

    const isParticipationEnabled = await _contract.methods
      .isParticipationEnabled()
      .call();

    setIsParticipateEnabled(isParticipationEnabled);

    const _isClaimEnabled = await _contract.methods.isClaimEnabled().call();

    setClaimEnabled(_isClaimEnabled);

    if (wallet.account) {
      const myContri = await _contract.methods
        .participation(wallet.account)
        .call();

      setMyContribution(Web3.utils.fromWei(myContri));

      const claimableBalance = await _contract.methods
        .tokensToBeClaim(wallet.account)
        .call();

      setMyClaimableBalance(toFixed(Web3.utils.fromWei(claimableBalance)));
    }


    const _maxBuyLimit = await _contract.methods.maxBuyLimit().call();
    setMaxBuyLimit(Web3.utils.fromWei(_maxBuyLimit))
    setContract(_contract);
  };

  useEffect(() => {
    setInterval(async() => {
      await loadContract();
    }, 2000);
    loadContract();
  }, [wallet.account,]);

  const renderButton = () => {
    if (!status) {
      return null;
    }

    if (status == -1) {
      return (
        <Button2 disabled style={{ width: "16rem" }}>
          <img src={i2} alt="" style={{ height: "100%", width: "100%" }} />
          <div
            style={{
              color: "#000",
              position: "absolute",
              margin: "0 0 0 ",
              fontWeight: "bold",
            }}
          >
            Participation Not Enabled
          </div>
        </Button2>
      );
    } else if (status == 1) {
      return (
        <Button2 disabled
        onClick={() => {
            handleParticipate();
          }}
        
        
        style={{ width: "16rem" }}>
          <img src={i2} alt="" style={{ height: "100%", width: "100%" }} />
          <div
            style={{
              color: "#000",
              position: "absolute",
              margin: "0 0 0 ",
              fontWeight: "bold",
            }}
          >
            Not Whitelisted
          </div>
        </Button2>
      );
    } else if (status == 2) {
      return (
        <Button2
          onClick={() => {
            handleParticipate();
          }}
          style={{ width: "16rem" }}
        >
          <img src={i2} alt="" style={{ height: "100%", width: "100%" }} />
          <div
            style={{
              color: "#000",
              position: "absolute",
              margin: "0 0 0 ",
              fontWeight: "bold",
            }}
          >
           Participate 
          </div>
        </Button2>
      );
    }
  };
  useEffect(() => {
    updateStatus();
  }, [wallet, isParticipateEnabled]);

  const updateStatus = () => {
    if (wallet.account) {
      const isWhitelisted = WHITELISTED_ADDRESSES.find(
        (item) => item.toLowerCase() === wallet.account.toLowerCase()
      );

      if (isWhitelisted) {
        if (isParticipateEnabled) {
          setStatus(2);
        } else {
          setStatus(-1);
        }
      } else {
        setStatus(1);
      }
    }
  };

 

  useEffect(() => {
    wallet.connect();
  }, []);

  useEffect(() => {
    const _moonAmount = Number(ethValue) / price;

    setMoonValue(_moonAmount?toFixed(_moonAmount):0);
  }, [ethValue]);

  useEffect(() => {
    let _ethAmount = price * Number(moonValue);

    setETHValue(_ethAmount?toFixed(_ethAmount):0);
  }, [moonValue]);


  function commify(n) {
    var parts = n.toString().split(".");
    const numberPart = parts[0];
    const decimalPart = parts[1];
    const thousands = /\B(?=(\d{3})+(?!\d))/g;
    return numberPart.replace(thousands, ",") + (decimalPart ? "." + decimalPart : "");
}

const getMyMax = ()=>{
  const defaultMaxBuy = maxBuyLimit*price;
  const finalMaxBuy =  defaultMaxBuy-Number(myContribution)
  console.log("Saaa",defaultMaxBuy)

  return finalMaxBuy
}

  return (
    <Sec>
      <Width>
        <Box>
          {/* <Flex>
                        <Sbox>
                            <T>From</T>
                            <div style={{display:'flex',alignItems:'center',backgroundColor:'#1A2029'}}>
                                <H style={{fontSize:'1.2rem',margin:'0 0 0 1rem'}}>ETH</H>
                                <input type="Text" placeholder="|   0.00"  className="input" style={{height:"3.75rem",border:'1px solid rgba(0,0,0,0.2)',borderRadius:'0.75rem',margin:'2rem 0 0 0',margin:'0 0 0 1rem'}} />
                            </div>
                            <T style={{fontSize:'0.9rem',margin:'0.5rem 0 0 0',color:'#C8C8C8'}} >1 ETH = 1,570 USD</T>
                        </Sbox>
                        <Sbox2>
                            <T>To</T>
                            <div style={{display:'flex',alignItems:'center',backgroundColor:'#1A2029'}}>
                                <H style={{fontSize:'1.2rem',margin:'0 0 0 1rem'}}>USD</H>
                                <input type="Text" placeholder="|   0.00"  className="input" style={{height:"3.75rem",border:'1px solid rgba(0,0,0,0.2)',borderRadius:'0.75rem',margin:'2rem 0 0 0',margin:'0 0 0 1rem'}} />
                            </div>
                            <T style={{fontSize:'0.9rem',margin:'0.5rem 0 0 0',color:'	#C8C8C8'}} >1 ETH = 1,570 USD</T>
                        </Sbox2>
                   </Flex> */}
          <Flex>
            <Sbox style={{ width: "100%" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  //   backgroundColor: "#1A2029",
                }}
              >
                <T>From</T>

                {wallet.account ? (
                  <T
                    className="clickable"
                    onClick={() => {
                      setETHValue(getMyMax());
                    }}
                  >
                    Max :{" "}
                    {Number(Web3.utils.fromWei(wallet.balance)).toFixed(4)} ETH
                  </T>
                ) : null}
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "#1A2029",
                }}
              >
                <H style={{ fontSize: "1.2rem", margin: "0 0 0 1rem" }}>ETH</H>
                <input
                  type="Text"
                  placeholder="|   0.00"
                  className="input"
                  value={ethValue}
                  onChange={(e) => {
                    setETHValue(e.target.value);
                  }}
                  style={{
                    height: "3.75rem",
                    border: "1px solid rgba(0,0,0,0.2)",
                    borderRadius: "0.75rem",
                    margin: "2rem 0 0 0",
                    margin: "0 0 0 1rem",
                    width: "100%",
                  }}
                />
              </div>
              {/* <T
                style={{
                  fontSize: "0.9rem",
                  margin: "0.5rem 0 0 0",
                  color: "	#C8C8C8",
                }}
              >
                ETH Per MOONS = 0.00000000001390699696
                <div />
                Max MOONS Per User = 13,740,047,770.70
                <div />
                Max ETH Commit = 0.1910827893869430
              </T> */}
            </Sbox>
          </Flex>

          <Flex>
            <Sbox2 style={{ width: "100%" }}>
              <T>To</T>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "#1A2029",
                }}
              >
                <H style={{ fontSize: "1.2rem", margin: "0 0 0 1rem" }}>
                  MOONS
                </H>
                <input
                  type="Text"
                  placeholder="|   0.00"
                  className="input"
                  value={moonValue}
                  onChange={(e) => {
                    setMoonValue(e.target.value);
                  }}
                  style={{
                    height: "3.75rem",
                    border: "1px solid rgba(0,0,0,0.2)",
                    borderRadius: "0.75rem",
                    margin: "2rem 0 0 0",
                    margin: "0 0 0 1rem",
                    width: "100%",
                  }}
                />
              </div>
            </Sbox2>
          </Flex>

          <Flex>
            <Sbox style={{ width: "100%" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  //   backgroundColor: "#1A2029",
                }}
              >
                <T>ETH Committed</T>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "#1A2029",
                }}
              >
                <H style={{ fontSize: "1.2rem", margin: "0 0 0 1rem" }}>ETH</H>
                <input
                  disabled
                  type="Text"
                  placeholder="|   0.00"
                  className="input"
                  value={myContribution}
                  style={{
                    height: "3.75rem",
                    border: "1px solid rgba(0,0,0,0.2)",
                    borderRadius: "0.75rem",
                    margin: "2rem 0 0 0",
                    margin: "0 0 0 1rem",
                    width:"100%"

                  }}
                />
              </div>
              {/* <T
                style={{
                  fontSize: "0.9rem",
                  margin: "0.5rem 0 0 0",
                  color: "	#C8C8C8",
                }}
              >
                ETH Per MOONS = 0.00000000001390699696
                <div />
                Max MOONS Per User = 13,740,047,770.70
                <div />
                Max ETH Commit = 0.1910827893869430
              </T> */}
            </Sbox>
          </Flex>

          <Flex>
            <Sbox2 style={{ width: "100%" }}>
              <T>Claimable MOONs</T>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "#1A2029",
                }}
              >
                <H style={{ fontSize: "1.2rem", margin: "0 0 0 1rem" }}>
                  MOONS
                </H>
                <input
                  disabled
                  value={myClaimableBalance}
                  type="Text"
                  placeholder="|   0.00"
                  className="input"
                  style={{
                    height: "3.75rem",
                    border: "1px solid rgba(0,0,0,0.2)",
                    borderRadius: "0.75rem",
                    margin: "2rem 0 0 0",
                    margin: "0 0 0 1rem",
                    width:"100%"
                  }}
                />
              </div>
            </Sbox2>
          </Flex>

          <Flex>
            <Sbox>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  //   backgroundColor: "#1A2029",
                }}
              >
                <T>Date and time of Claim beginning.</T>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "#1A2029",
                }}
              >
                <input
                  disabled
                  type="Text"
                  placeholder="0.00"
                  value={"21 July 2022. 1200 UTC+1"}
                  className="input"
                  style={{
                    height: "3.75rem",
                    border: "1px solid rgba(0,0,0,0.2)",
                    borderRadius: "0.75rem",
                    margin: "2rem 0 0 0",
                    margin: "0 0 0 1rem",
                  }}
                />
              </div>
              {/* <T
                style={{
                  fontSize: "0.9rem",
                  margin: "0.5rem 0 0 0",
                  color: "	#C8C8C8",
                }}
              >
                ETH Per MOONS = 0.00000000001390699696
                <div />
                Max MOONS Per User = 13,740,047,770.70
                <div />
                Max ETH Commit = 0.1910827893869430
              </T> */}
            </Sbox>

            <Sbox>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  //   backgroundColor: "#1A2029",
                }}
              >
                <T> Max Buy Limit</T>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "#1A2029",
                }}
              >
                <input
                  disabled
                  type="Text"
                  placeholder="0.00"
                  value={`${commify(maxBuyLimit)} MOONS`}
                  className="input"
                  style={{
                    height: "3.75rem",
                    border: "1px solid rgba(0,0,0,0.2)",
                    borderRadius: "0.75rem",
                    margin: "2rem 0 0 0",
                    margin: "0 0 0 1rem",
                  }}
                />
              </div>
              {/* <T
                style={{
                  fontSize: "0.9rem",
                  margin: "0.5rem 0 0 0",
                  color: "	#C8C8C8",
                }}
              >
                ETH Per MOONS = 0.00000000001390699696
                <div />
                Max MOONS Per User = 13,740,047,770.70
                <div />
                Max ETH Commit = 0.1910827893869430
              </T> */}
            </Sbox>
          </Flex>

          <ButtonContainer>
            <Button style={{ margin: "2rem 0 0 0", width: "16rem" }}>
              <img src={i1} alt="" style={{ height: "100%", width: "100%" }} />
              <div
                onClick={() => {
                  wallet.connect();
                }}
                style={{
                  color: "#000",
                  position: "absolute",
                  margin: "0 0 0",
                  fontWeight: "bold",
                }}
              >
                {wallet.account
                  ? `${wallet.account.slice(0, 4)}....${wallet.account.slice(
                      wallet.account.length - 4,
                      wallet.account.length
                    )}`
                  : "CONNECT WALLET"}
              </div>
            </Button>
            {renderButton()}

       {isClaimEnabled && Number(myClaimableBalance)>0?     <Button2
          onClick={() => {
            handleClaim();
          }}
          style={{ width: "16rem" }}
        >
          <img src={i2} alt="" style={{ height: "100%", width: "100%" }} />
          <div
            style={{
              color: "#000",
              position: "absolute",
              margin: "0 0 0 ",
              fontWeight: "bold",
            }}
          >
           Claim Moon
          </div>
        </Button2>:null}
          </ButtonContainer>
        </Box>
      </Width>
    </Sec>
  );
};

export default Page6a;
