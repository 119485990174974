import React, { useState, useEffect } from "react";
import styled from "styled-components";
import "./Bg.css";
import { motion } from "framer-motion";
import bg from "./bg.jpg";
import center from "./center.svg";
import { useSelector, useDispatch } from "react-redux";
import theme from "styled-theming";
import { BsBoxArrowUp } from "react-icons/bs";
import { Link, animateScroll as scroll } from "react-scroll";
import { Button } from "../../components/Navbar/Navbar";
import { useWallet } from "use-wallet";
import { T } from "../Page2/Page2";
import btn from "./btn.png";

// const iconColor = theme("theme", {
//     light: "linear-gradient(to right, #0f0c29, #302b63, #24243e)",
//     dark: "linear-gradient(to right, #fc00ff, #00dbde)",
//   });

const HomePage = styled.section`
  display: flex;
  //
  align-items: center;
  justify-content: space-evenly;
  min-height: 100vh;
  width: 100%;
  padding: 8rem 0 5rem 0;

  background-image: url(${bg});
  background-size: 100% 100%;
  background-position: center center;
  background-repeat: no-repeat;

  background-color: #000;

  @media only screen and (max-width: 1300px) {
    background-size: auto 100%;
    background-position: right center;
  }

  @media only screen and (max-width: 768px) {
    width: 100%;

    background-size: auto 100%;
    flex-direction: column;
  }
`;

const Width = styled.div`
  width: 1300px;
  display: flex;

  //  padding: 3rem 0 0 0;
  align-items: center;
  justify-content: space-between;

  @media only screen and (max-width: 1350px) {
    width: 80%;
  }
  @media only screen and (max-width: 1100px) {
    flex-direction: column-reverse;
  }

  @media only screen and (max-width: 510px) {
    width: 98%;
    //margin: 4rem  0 0 0;
  }
`;
const Background = styled.div`
  // background-image: url(${bg});
  background-size: 100% 100%;
  background-position: center center;
  background-repeat: no-repeat;
  width: 90%;
  height: 30rem;
  position: relative;
  display: flex;
  //
  align-items: center;
  justify-content: center;
  margin: 8rem 0 0 0;
`;

const Left = styled(motion.div)`
  width: 40rem;
  // background: red;
  // margin: -8rem 0 0 0;
  @media only screen and (max-width: 1100px) {
    width: 50%;
    margin: 2rem 0 0 0;
  }
  @media only screen and (max-width: 998px) {
    width: 85%;
  }

  @media only screen and (max-width: 768px) {
    width: 85%;
  }
`;

const Right = styled(motion.div)`
  display: flex;
  //justify-content: center;
  flex-direction: column;
  width: 40%;
  // height: 45rem;

  @media only screen and (max-width: 1100px) {
    width: 50%;

    height: auto;
  }
  @media only screen and (max-width: 998px) {
    width: 65%;
  }

  @media only screen and (max-width: 768px) {
    width: 25rem;
  }

  @media only screen and (max-width: 768px) {
    width: 90%;
  }
`;

const H = styled.h1`
  font-size: 4.5rem;

  text-align: right;
  font-weight: 800;

  line-height: 1;

  @media only screen and (max-width: 1200px) {
    font-size: 3rem;
    text-align: left;
  }

  @media only screen and (max-width: 768px) {
    font-weight: 510;
    font-size: 1.5rem;
    font-weight: bold;
  }
`;

const Hs = styled.h1`
  font-size: 2.75rem;

  line-height: 1;
  margin: 2rem 0 0 0;

  @media only screen and (max-width: 1200px) {
    font-size: 1.75rem;
  }

  @media only screen and (max-width: 768px) {
    font-weight: 510;
    font-size: 1.25rem;
    font-weight: bold;
  }
`;

const Center = styled.div`
  width: 30rem;
  height: 40rem;
  position: absolute;
  @media only screen and (max-width: 768px) {
    width: 98%;
    flex-direction: column;
  }
`;

const ShowButton = styled(Link)`
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  background-color: #f98e1f; //
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  position: fixed;
  height: 4rem;
  width: 3rem;
  border-radius: 0.5rem;
  transition: 0.5s linear;
  right: 3rem;
  bottom: 10rem;
  display: grid;
  place-items: center;
  z-index: 7;
  @media only screen and (max-width: 768px) {
    right: 2rem;
    bottom: 5rem;
  }
`;
const Tu = styled(T)`
  max-width: 360px;
  margin: 2rem 0 0 0;
`;

const Home = () => {
  const darkThemeEnabled = useSelector(
    (state) => state.preferences.darkThemeEnabled
  );
  const dispatch = useDispatch();
  const wallet = useWallet();

  const renderButton = () => {
    return wallet.account ? (
      <Button
        type="button"
        style={{ padding: "0 1rem", minWidth: "11rem" }}
        onClick={() => {
          wallet.connect();
        }}
        to="donate"
        spy={true}
        smooth={true}
        offset={150}
      >
        {wallet.account.slice(0, 10)}...
      </Button>
    ) : (
      <Button
        type="button"
        style={{ padding: "0 1rem", minWidth: "11rem" }}
        onClick={() => {
          wallet.connect();
        }}
        to="donate"
        spy={true}
        smooth={true}
        offset={150}
      >
        Donate
      </Button>
    );
  };

  const [showButton, setShowButton] = useState(false);

  const showButtonTop = () => {
    // console.log(window.scrollY)
    if (window.scrollY >= 600) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  };

  useEffect(() => {
    showButtonTop();
    // adding the event when scroll change background
    window.addEventListener("scroll", showButtonTop);
  });

  return (
    <HomePage id="home">
      <Width>
        <Left>
          <H>AMPLIFIED $LUNC CLASSIC</H>
          <Hs>BURNING PROTOCOL</Hs>

          <Tu>
            Alone, there's only so much we can do. Together, we can take over
            the world. Let’s come together and Save the Moon
          </Tu>

          <Button
            style={{ margin: "2rem 0 0 0", width: "16rem" }}
            onClick={() => {
              window
                .open(
                  "https://app.uniswap.org/#/swap?&outputCurrency=0xb70f8a3029cf10fd9e4a072a280484691ac4296a",
                  "_blank"
                )
                .focus();
            }}
          >
            <img src={btn} alt="" style={{ height: "100%", width: "100%" }} />
            <div
              style={{
                color: "#000",
                position: "absolute",
                margin: "0 0 0 2rem",
                fontWeight: "bold",
              }}
            >
              BUY ON UNISWAP
            </div>
          </Button>
        </Left>

        <Right>
          <img src={center} alt="" style={{ width: "100%", height: "100%" }} />
        </Right>
      </Width>

      {showButton ? (
        <ShowButton type="button" to="home" spy={true} smooth={true}>
          <BsBoxArrowUp size="2rem" />
        </ShowButton>
      ) : null}
    </HomePage>
  );
};

export default Home;
