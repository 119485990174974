import React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import theme from 'styled-theming';
import { FactionData } from '../config/config'
import FactionCard from '../../components/card/FactionCard'
import bg from './bg.jpg'
import one from './1.png'
import two from './2.png'

export const backgroundColor = theme("theme", {
    light: "#000000",
    dark: "#E5E5E5",
  });

const Width = styled.div`
      width: 1300px;
 
   display: flex;
   flex-direction: column;

    //align-items: center;
    //justify-content: center;
     
    @media only screen and (max-width: 1300px) {
        width: 85%;
    }
  
`;

const StyleCard = styled.div`
      width: 1300px;
      display: grid;
    
      grid-template-columns: repeat(auto-fit, minmax(26rem, 1fr));
 
 
      @media only screen and (max-width: 1600px) {
        width: 98%;
     
      }


      @media only screen and (max-width: 510px) {
    
        width: 100%;
        grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
      }
`

const Background = styled.div`
    background-color: rgba(0, 29, 35, 1);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background-image: url(${one});
    background-size: 100% 100%;
    background-repeat: no-repeat;
    //align-items: center;
    min-height: 20rem;
    
    
      padding: 3rem 2rem 4rem 2rem;
      position: relative;
`;

const Background2 = styled.div`
  background-color: rgba(0, 29, 35, 1);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background-image: url(${two});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  //align-items: center;
  min-height: 20rem;

      margin: 4rem 0 0 0;
    padding: 3rem 2rem 4rem 2rem;
    position: relative;
`;

const Dashboard = styled.div`
    background-color: rgba(2, 169, 92, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1300px;
    padding: 2rem 0;
    border-radius: 1.2rem;
 
    margin: 3rem 0 -6rem 0;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;

    @media only screen and (max-width: 1350px) {
      width: 98%;
      display: grid;
      grid-gap: 2rem;
      grid-template-columns: repeat(auto-fit, minmax(21rem, 1fr));
    }

    @media only screen and (max-width: 1093px) {
      margin: 3rem 0 -12rem 0;
    }
`;



export const T = styled.p`
    color: #FFF;
    font-weight: 500;
    font-size: 1.2rem;
   // margin-bottom: 2rem;
    text-align: left;
     // font-weight: bold;
      letter-spacing: 0.05rem;
      line-height: 1.5;
    @media only screen and (max-width: 768px) {
        font-weight: 510;
        font-size: 1rem;
       
      }
`;

const H = styled.h1`
    color: #FFF;
    font-weight: bolder;
    font-size: 3.5rem;
   // 
    text-align: left ;
     // font-weight: bold;
      letter-spacing: 0.05rem;
      line-height: 1.5;
    @media only screen and (max-width: 768px) {
        font-weight: bolder;
        font-size: 2rem;
        margin-bottom: 2rem;
      }
`;

const Grid = styled.div`
      display: grid;
  place-items: center;
  width: 90%;
 
  grid-template-columns: repeat(auto-fit, minmax(9rem, 1fr));
  grid-gap: 2rem;
  text-align: left;
`

const Page4 = (props) => {

  const Sec = styled.section`
   min-height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000;
    background-image: url(${bg});
    background-size: 100% 100%;
    background-repeat: no-repeat;
    flex-direction: column;
    padding: ${props.big ? '6rem 0 10rem 0' : '2rem 0'};

    @media only screen and (max-width: 998px) {
        min-height: 100vh;
        width: 100%;
        padding: 2rem 0;
        flex-direction: column;
        background-size: auto 100%;
      }
`;

    return (
        <Sec id="fund">
          <Width>
            <Background>
              <div style={{width: '90%'}}>
                <H>BUYING $MOONS</H>
              </div>
                <Grid>
                  <T>ETH/$LUNC </T>
                  <svg width="67" height="24" viewBox="0 0 67 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M66.0607 13.0607C66.6464 12.4749 66.6464 11.5251 66.0607 10.9393L56.5147 1.3934C55.9289 0.807611 54.9792 0.807611 54.3934 1.3934C53.8076 1.97919 53.8076 2.92893 54.3934 3.51472L62.8787 12L54.3934 20.4853C53.8076 21.0711 53.8076 22.0208 54.3934 22.6066C54.9792 23.1924 55.9289 23.1924 56.5147 22.6066L66.0607 13.0607ZM0 13.5H65V10.5H0V13.5Z" fill="#F98E1F"/>
                  </svg>

                  <T>2% $LUNC BURN</T>
                  <svg width="67" height="24" viewBox="0 0 67 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M66.0607 13.0607C66.6464 12.4749 66.6464 11.5251 66.0607 10.9393L56.5147 1.3934C55.9289 0.807611 54.9792 0.807611 54.3934 1.3934C53.8076 1.97919 53.8076 2.92893 54.3934 3.51472L62.8787 12L54.3934 20.4853C53.8076 21.0711 53.8076 22.0208 54.3934 22.6066C54.9792 23.1924 55.9289 23.1924 56.5147 22.6066L66.0607 13.0607ZM0 13.5H65V10.5H0V13.5Z" fill="#F98E1F"/>
                  </svg>
                  <T>2% $MOONS DISTRIBUTED TO HOLDERS</T>
                  <svg width="67" height="24" viewBox="0 0 67 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M66.0607 13.0607C66.6464 12.4749 66.6464 11.5251 66.0607 10.9393L56.5147 1.3934C55.9289 0.807611 54.9792 0.807611 54.3934 1.3934C53.8076 1.97919 53.8076 2.92893 54.3934 3.51472L62.8787 12L54.3934 20.4853C53.8076 21.0711 53.8076 22.0208 54.3934 22.6066C54.9792 23.1924 55.9289 23.1924 56.5147 22.6066L66.0607 13.0607ZM0 13.5H65V10.5H0V13.5Z" fill="#F98E1F"/>
                    </svg>
                  <T>$MOONS</T>
                </Grid>
            </Background>

            <Background2>
              <div style={{width: '90%'}}>
                  <H>SELLING $MOONS</H>
                </div>
                  <Grid>
                    <T>$MOONS</T>
                    <svg width="67" height="24" viewBox="0 0 67 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M66.0607 13.0607C66.6464 12.4749 66.6464 11.5251 66.0607 10.9393L56.5147 1.3934C55.9289 0.807611 54.9792 0.807611 54.3934 1.3934C53.8076 1.97919 53.8076 2.92893 54.3934 3.51472L62.8787 12L54.3934 20.4853C53.8076 21.0711 53.8076 22.0208 54.3934 22.6066C54.9792 23.1924 55.9289 23.1924 56.5147 22.6066L66.0607 13.0607ZM0 13.5H65V10.5H0V13.5Z" fill="#F98E1F"/>
                    </svg>

                    <T>2% $MOONS BURN</T>
                    <svg width="67" height="24" viewBox="0 0 67 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M66.0607 13.0607C66.6464 12.4749 66.6464 11.5251 66.0607 10.9393L56.5147 1.3934C55.9289 0.807611 54.9792 0.807611 54.3934 1.3934C53.8076 1.97919 53.8076 2.92893 54.3934 3.51472L62.8787 12L54.3934 20.4853C53.8076 21.0711 53.8076 22.0208 54.3934 22.6066C54.9792 23.1924 55.9289 23.1924 56.5147 22.6066L66.0607 13.0607ZM0 13.5H65V10.5H0V13.5Z" fill="#F98E1F"/>
                    </svg>
                    <T>2% $MOONS DISTRIBUTED TO HOLDERS </T>
                    <svg width="67" height="24" viewBox="0 0 67 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M66.0607 13.0607C66.6464 12.4749 66.6464 11.5251 66.0607 10.9393L56.5147 1.3934C55.9289 0.807611 54.9792 0.807611 54.3934 1.3934C53.8076 1.97919 53.8076 2.92893 54.3934 3.51472L62.8787 12L54.3934 20.4853C53.8076 21.0711 53.8076 22.0208 54.3934 22.6066C54.9792 23.1924 55.9289 23.1924 56.5147 22.6066L66.0607 13.0607ZM0 13.5H65V10.5H0V13.5Z" fill="#F98E1F"/>
                      </svg>
                    <T>ETH/$LUNC</T>
                  </Grid>
            </Background2>
          </Width>
        </Sec>
    )
}

export default Page4
