
const RPCs = [
    "https://rpc.ankr.com/eth",
    "https://eth-rpc.gateway.pokt.network",

"https://rpc.flashbots.net"
]


const getRandomRPC = ()=>{
    return  RPCs[Math.floor(Math.random()*RPCs.length)];

}
export default {
    PRESALE_ADDRESS:"0x7b36Bc82a14f38De98758fD409400660fDB3aCB7",
    RPC:getRandomRPC(),
    MOON:"0xb70f8a3029cf10fd9e4a072a280484691ac4296a"
}


