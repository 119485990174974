import React from 'react'
import styled from 'styled-components'
// import img from '../../components/Navbar/logo.png'
import Tilt from 'react-parallax-tilt';
import bg from './bg.png'
import theme from "styled-theming";
import left from './m1.png'
import right from './m2.png'
import { motion } from 'framer-motion'
import element from './element.png'
import { Button } from '../../components/Navbar/Navbar';
import click from './click.png'

export const backgroundColor = theme("theme", {
    light: "#000000",
    dark: "#E5E5E5",
  });  

const Sec = styled.section`

    display: flex;
    align-items: center;
    //justify-content: center;
    flex-direction: column;
    min-height:  70rem;
    background-color: #FFF;
    width: 100%;
  
    padding: 5rem 0;
    background-color: #000;

    background-image: url(${bg});
    background-size: 100% 100%;
    background-position: center center;
    background-repeat: no-repeat;
    
    @media only screen and (max-width: 768px) {
        min-height: 100vh;
      background-image: none;
        flex-direction: column;
        justify-content: space-around;
      }
`;

const Width = styled.div`
      width: 1100px;
      display: flex;
      flex-direction: column;
        align-items: center;
        justify-content: space-between;

        @media only screen and (max-width: 1200px) {
          width: 80%;
      }
    
    
    @media only screen and (max-width: 1000px) {
      width: 98%;   
  }

`

export const T = styled.p`
font-family: 'Space Grotesk', sans-serif;
    font-weight: 500;
    font-size: 1rem;
   // margin-bottom: 2rem;
    text-align: left;
     // font-weight: bold;
      letter-spacing: 0.05rem;
      line-height: 1.5;
    @media only screen and (max-width: 768px) {
        font-weight: 510;
        font-size: 1rem;
       
      }
`;

export const H = styled.h1`
    
    font-size: 1.8rem;
    font-weight: 500;
    margin: 0 2rem 0 0;
    text-align: left !important;
    @media only screen and (max-width: 768px) {
        font-weight: 510;
        font-size: 1.2rem;
        font-weight: bold;
      }
`;

const Hs = styled.h1`
    
    font-size: 7rem;
    font-weight: bolder;
    margin: 0 2rem 0 0;
    text-align: left !important;
    @media only screen and (max-width: 768px) {
        font-weight: 510;
        font-size: 4rem;
        font-weight: bold;
      }
`;


const Center = styled.div`
      width: 1000px;
      display: flex;
     // flex-direction: column;
      background-color: #FAF7DC;
      
        border-radius: 1.5rem;
        padding: 3rem 3rem 3rem 15rem;
        background-image: url(${left});
        background-size: 25rem 20rem;
        background-position: left bottom !important;
        background-repeat: no-repeat;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    @media only screen and (max-width: 1400px) {
        width: 95%;
    }
    @media only screen and (max-width: 1100px) {
       
        padding: 3rem;
        background-image: none;
    }

`
const Center1 = styled.div`
      width: 1000px;
      display: flex;
     // flex-direction: column;
      background-color: #FAF7DC;
      
        border-radius: 1.5rem;
        padding: 3rem 3rem 3rem 15rem;
        background-image: url(${right});
        background-size: 25rem 20rem;
        background-position: left bottom !important;
        background-repeat: no-repeat;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    @media only screen and (max-width: 1400px) {
        width: 95%;
    }
    @media only screen and (max-width: 1100px) {
       
        padding: 3rem;
        background-image: none;
    }

`

const Container = styled.div`
  width: 1400px;
  

    @media only screen and (max-width: 1400px) {
      width: 85%;
  }


  @media only screen and (max-width: 1000px) {
  width: 98%;   
  }
`
const Container1 = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 3rem 0 0 0;
`

const Left = styled(motion.div)`
    width: 100%;
      
        @media only screen and (max-width: 998px) {
            width: 85%;
        }

    @media only screen and (max-width: 768px) {
     
        width: 90%;
      
       
      }
`;

const Right = styled(motion.div)`
        display: flex;
        //justify-content: center;
        flex-direction: column;
        width: 40%;
       // height: 45rem;


          @media only screen and (max-width: 1100px) {
            width: 50%;
          
            height: auto;
        }
        @media only screen and (max-width: 998px) {
            width: 65%;
           
        }

      @media only screen and (max-width: 768px) {
        width: 25rem; 
        
      }

      @media only screen and (max-width: 768px) {
        width: 90%; 
        
      }
`;

const Margin = styled.div`
      margin: 3rem 0 0 0;

      @media only screen and (max-width: 768px) {
        margin: 1rem  0 0 0;
        
      }

`

const Page2 = () => {
  
    return (
        <Sec id="">

          <Container>
            <div style={{display:'flex',alignItems:'center'}}>
              <Hs>About</Hs>
              <svg width="114" height="115" viewBox="0 0 114 115" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M57 0L67.4793 46.9288L114 57.5L67.4793 68.0712L57 115L46.5207 68.0712L0 57.5L46.5207 46.9288L57 0Z" fill="#F98E1F"/>
              </svg>
            </div>

          </Container>

          <Width>
            <Left>
              <Margin>
                <T style={{fontSize:'1.2rem'}}>
                  We are not $LUNC. We are believers in it as an ecosystem. Its future is still something that has merit considering the capabilities it has built up through its ecosystem partners that it has garnered just over the last year. $LUNC has a great ethos, a robust tech platform, and an even better community. But it is lacking in how it is governed and created a situation where its potential is stifled through the crash. 
                </T>
                <T style={{margin:'2rem 0 0 0',fontSize:'1.2rem'}}>
                  All that is needed is for the community to listen and implement more sustainable burn mechanics in its ecosystem to ensure longevity and sustainability.
                </T>
              </Margin>
              <div style={{display:'flex',alignItems:'center'}}>
                <a href="http://bit.ly/3OFElK9" target='_blank'>
                  <img src={click} alt='' style={{height:'4rem',width:'4rem',margin:'4rem 1rem 0 0'}} />
                </a>
                <T style={{fontSize:'1.2rem',textTransform:'uppercase',margin:'4rem 0 0 0'}}>
                  Save The Moon is audited by PeckShield, one of the top leading firms in the Blockchain Security industry. Click the icon to view the report.
                </T>
              </div>
            </Left>
          </Width>

        </Sec>
    )
}

export default Page2





