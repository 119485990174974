import React,{useEffect, useState} from 'react'
import styled from 'styled-components'
// import img from '../../components/Navbar/logo.png'
import Tilt from 'react-parallax-tilt';
import bg from './bg.jpg'
import g from './img.jpg'
import theme from "styled-theming";
import left from './donor.png'
import right from './frame.png'
import { motion } from 'framer-motion'
import { H } from '../Page2/Page2';
import {TiTick} from 'react-icons/ti'
import img from './img.png'
import Web3 from 'web3';
import config from '../../config';
import PresaleABI from "../../config/Presale.json";
import MoonABI from "../../config/Moon.json";

export const backgroundColor = theme("theme", {
    light: "#000000",
    dark: "#E5E5E5",
  });  

const Sec = styled.section`

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 50rem;
    background-color: #FFF;
    width: 100%;

    background-image: url(${bg});
    background-size: 100% 100%;
    background-position: center center;
    background-repeat: no-repeat;
  
    padding: 5rem 0;
    
    @media only screen and (max-width: 768px) {
        
        padding:  2rem 0;
        flex-direction: column;
        justify-content: space-around;
      }
`;

const Width = styled.div`
      width: 1300px;
      display: flex;
        
        align-items: center;
        justify-content: space-between;

        @media only screen and (max-width: 1400px) {
          width: 998px;
      }
    
    @media only screen and (max-width: 1100px) {
        flex-direction: column;
    }
    @media only screen and (max-width: 1000px) {
      width: 98%;   
  }

`

const Right = styled.div`
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        height: 22rem;
        background-color: rgba(15, 19, 34, 0.6);
        
    background-image: url(${right});
    background-size: 100% 100%;
    background-position: center center;
    background-repeat: no-repeat;
    
        @media only screen and (max-width: 1100px) {
          margin: 3rem 0 0 0;
      }

        @media only screen and (max-width: 998px) {
            width: 65%;
           
        }

      @media only screen and (max-width: 768px) {
        width: 95%; 
       
      }
`;


export const T = styled.p`
    color: rgba(0, 29, 35, 1);
    //font-weight: bold;
    font-size: 1.25rem;
    margin-bottom: 2rem;
    text-align: left;
    line-height: 1.6;
    @media only screen and (max-width: 768px) {
        font-weight: 510;
        font-size: 1rem;
       
      }
`;



const Ht = styled.h1`
    color: #e69500;
    font-size: 2.2rem;
    font-weight: bold;
    margin-bottom: 2rem;
    text-align: left;
    @media only screen and (max-width: 768px) {
        font-weight: 510;
        font-size: 1.2rem;
        font-weight: bold;
      }
`;


const Founder = styled.div`
    
  background-image: url(${g});
  background-size: 90% 100%;
  background-repeat: no-repeat;
  padding: 2rem 1rem;
  margin: 1rem 0 0 0;

  @media only screen and (max-width: 510px) {
  
    background-size: 100% 100%;
  }

`
const Heading = styled.div`
    
    font-size: 0.75rem;
   color: rgba(0,0,0,0.2);
    letter-spacing: 0.1rem;
   
    @media only screen and (max-width: 768px) {
        font-weight: 510;
        font-size: 1.2rem;
       
      }
`;

const Hs = styled(H)`
   text-align: center;
    font-size: 2.5rem;
    margin: 1.2rem 0;
    font-weight: bold;
    @media only screen and (max-width: 768px) {
        font-weight: 510;
        font-size: 1.2rem;
        font-weight: bold;
      }
`;

const HeadingContainer = styled.div`
 
`
const Img = styled.div
`
  background-image: url(${img});
  background-size: 100% 100%;
  background-position: center center;
  background-repeat: no-repeat;
  height: 4.5rem;
  width: 3rem;
  display: grid;
  place-items: center;
  font-size: 1.5rem;

`
const PC = styled.div`
      display: flex;
      align: center;
      @media only screen and (max-width: 768px) {
        display: none;
      }
`

const Mobile = styled.div`
  display: none;
  @media only screen and (max-width: 768px) {
    display: block;
    font-weight: bold;
    font-size: 1.75rem;
  }
`

const Page3a = () => {

  const [luncBurnt,setLuncBurnt] = useState("17793618.75")
  
  const loadData = async()=>{
    // const web3 = new Web3(config.RPC)
    // let _contract = new web3.eth.Contract(MoonABI, config.MOON);
    // let _luncBurnt = await _contract.methods.totalLunaConverted().call()
    // _luncBurnt = Number(_luncBurnt)
    // if(_luncBurnt>0){
    //   _luncBurnt = _luncBurnt/1e18;
    //   setLuncBurnt(_luncBurnt.toString())
    // }
    // console.log({_luncBurnt})
  }
  useEffect(()=>{
    loadData()
  },[])
    return (
        <Sec id="rarity">

            <Width>
                
               

                <Right>

                  <Hs>
                    $LUNC BURNT
                  </Hs>
                 
                  <PC>
                   {luncBurnt.split("").map((n)=>{
                      return   <Img>
                      {n}
                    </Img>
                   })}
                  

                  </PC>

                  <Mobile>
                    17,793,618.75
                  </Mobile>
                    
                   
                </Right>

            </Width>

        </Sec>
    )
}

export default Page3a





