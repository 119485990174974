import React from 'react'
import styled from 'styled-components'
import bg from './bg.png'
// import { Button, IconContainer } from '../../components/Navbar/Navbar';
import { H,T } from '../Page2/Page2'
import two from './img.png'
import mbg from './bg.png'
import img3 from './img2.png'
import img4 from './4.svg'
import img5 from './5.svg'
import { motion } from 'framer-motion'

const Sec = styled.section`
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #000;
    padding: 8rem 0 3rem 0;

    background-image: url(${mbg});
    background-size: 100% 100%;
    background-position: center center;
    background-repeat: no-repeat;

    @media only screen and (max-width: 1300px) {
      
      background-size: auto 100%;
    }

    @media only screen and (max-width: 768px) {
        
        background-size: auto 100%;
      }
`;

const Width = styled.div`
      width: 1200px;
      display: flex;
      flex-direction: column;
        //align-items: center;
        justify-content: space-between;
    
    @media only screen and (max-width: 1400px) {
        width: 95%;
    }
    @media only screen and (max-width: 1000px) {
        display: none;
    }
`
const Width1 = styled.div`
      display: none;
    @media only screen and (max-width: 1100px) {
      width: 95%;
      display: flex;
      flex-direction: column;
        //align-items: center;
        
    }
`

const IconContainer = styled.div`
     display: flex;
  margin: 2rem 0 0 0;
    align-items: center;
    justify-content: space-between;
  width: 30rem;
  height: 20rem


  @media only screen and (max-width: 800px) {
      width: 99%;
    
  }
  @media only screen and (max-width: 600px) {
      width: 99%;
      
  }
`

const Left = styled(motion.div)`
        width: 55%;
     // background: red;

          @media only screen and (max-width: 1100px) {
            width: 50%;
        }
        @media only screen and (max-width: 998px) {
            width: 85%;
        }

    @media only screen and (max-width: 768px) {
     
        width: 85%;
      
       
      }
`;

const Right = styled(motion.div)`
        display: flex;
        //justify-content: center;
        flex-direction: column;
        width: 40%;
       // height: 45rem;


          @media only screen and (max-width: 1100px) {
            width: 50%;
            margin: 3rem  0 0 0;
            height: auto;
        }
        @media only screen and (max-width: 998px) {
            width: 65%;
           
        }

      @media only screen and (max-width: 768px) {
        width: 25rem; 
        
      }

      @media only screen and (max-width: 768px) {
        width: 90%; 
        
      }
`;

const Ht = styled.h1`
    color: #FFF;
    font-size: 3rem;
  
    text-align: center;
   font-weight: bolder;
    line-height: 1.2;
    margin-bottom: 2rem;


    @media only screen and (max-width: 768px) {
        font-weight: 510;
        font-size: 1.5rem;
        font-weight: bold;
      }
`;

const Hori = styled.div`
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 3rem  0;

      @media only screen and (max-width: 1100px) {
        width: 768px;
      }


      @media only screen and (max-width: 800px) {
        width: 99%;
      }
`
const Img = styled.div`
      height: 5rem;
      width: 15rem;
      margin: 0 0 0 7.5rem;

      @media only screen and (max-width: 1100px) {
        margin: 0;
      }

      @media only screen and (max-width: 1100px) {
        height: 4rem ;
        width: 12rem;
      }
`

const Img2 = styled.div`
      height: 5rem;
      width: 15rem;
      margin: 0 7.5rem 0 0;

      @media only screen and (max-width: 1100px) {
        margin: 0;
      }
`
const Line = styled.div `
      width: 2px;
      min-height: 4rem;
      background: #F98E1F;
      margin: 0 0 0 1rem;
`
const First = styled.div`
  display: flex;
  align-items: center;
  width: 20rem;
  text-align: right;
 // background: red;

  @media only screen and (max-width: 1100px) {
    width: 15rem;
    
  }
`

const Third = styled.div`
      display: flex;
      align-items: center;
      width: 20rem;
`

const Content = styled.div`
  font-size: 1.2rem;
  @media only screen and (max-width: 768px) {
    
    font-size: 1rem;
  }
`

const Page2 = () => {
  
    return (
        <Sec id="work">

          <Width>

            <Ht>How it Works</Ht>

             <Hori>
                <First></First>
                <Img>
                  <img src={two} alt='' style={{height:'100%',width:'100%'}} />
                </Img>
                <Third>
                  <Line />
                  <div style={{margin:'0 0 0 1rem',fontSize:'1.2rem'}}>
                    There is a 4% 
                    <div>transfer tax for</div>
                    <div>buying/selling $MOONS.</div>
                  </div>
                 
                </Third>
             </Hori>

             <Hori>
                <First>
                  <div style={{margin:'0 1rem 0 0',fontSize:'1.2rem'}}>
                  2% will be
                  <div>converted to $LUNC</div>
                  <div>and sent to burn.</div>
                  </div>
                  <Line />
                </First>
                <Img2>
                  <img src={img3} alt='' style={{height:'100%',width:'100%'}} />
                </Img2>
                <Third>
                  
                </Third>
             </Hori>

             <Hori>
                <First></First>
                <Img>
                  <img src={two} alt='' style={{height:'100%',width:'100%'}} />
                </Img>
                <Third>
                  <Line />
                  <div style={{margin:'0 0 0 1rem',fontSize:'1.2rem'}}>
                    2% WILL BE 
                    <div></div>DISTRIBUTED AMONG 
                    <div></div>$MOONS HOLDERS
                  </div>
                 
                </Third>
             </Hori>

             <Hori>
                <First>
                  <div style={{margin:'0 1rem 0 0',fontSize:'1.2rem'}}>
                  Swapping $LUNC for 
                  <div/>$MOONS encompasses a 
                  <div/>reflection and burn 
                  <div/>mechanic which can be <div/>turned off in the future.
                  </div>
                  <Line />
                </First>
                <Img2>
                  <img src={img3} alt='' style={{height:'100%',width:'100%'}} />
                </Img2>
                <Third>
                  
                </Third>
             </Hori>

             <Hori style={{margin:'0'}}>
                <First></First>
                <Img>
                  <img src={two} alt='' style={{height:'100%',width:'100%'}} />
                </Img>
                <Third>
                  <Line />
                  <div style={{margin:'0 0 0 1rem',fontSize:'1.2rem'}}>
                  $MOONS will go towards<div/> a $LUNC buyback and 
                  <div />burn, while $MOONS will <div/>be accumulated and
                  <div/> distributed to all $MOONS <div/>holders. While the 
                  <div/>selling of $MOONS will go <div/>towards a burn and 
                  <div/>distribution to $MOONS <div/>holders alike.
                  </div>
                 
                </Third>
             </Hori>

             <Hori>
                <First>
                  <div style={{margin:'0 1rem 0 0',fontSize:'1.2rem'}}>
                  THE DUAL BURNING<div/> MECHANISM WILL REDUCE 
                  <div/>THE CIRCULATING SUPPLY<div/> OF BOTH $LUNC AND $MOONS
                  </div>
                  <Line />
                </First>
                <Img2>
                  <img src={img3} alt='' style={{height:'100%',width:'100%'}} />
                </Img2>
                <Third>
                  
                </Third>
             </Hori>

             

          </Width>       

          <Width1>
            <Hori>
                <Img>
                  <img src={two} alt='' style={{height:'100%',width:'100%'}} />
                </Img>
                <Third>
                  <Line />
                  <Content style={{margin:'0 0 0 1rem'}}>
                    There is a 4% 
                    <div>transfer tax for</div>
                    <div>buying/selling $MOONS.</div>
                  </Content>
                 
                </Third>
             </Hori>

             <Hori>
                <Img>
                  <img src={two} alt='' style={{height:'100%',width:'100%'}} />
                </Img>
                <Third>
                  <Line />
                  <Content style={{margin:'0 0 0 1rem'}}>
                  2% will be
                  <div>converted to $LUNC</div>
                  <div>and sent to burn.</div>
                  </Content>
                 
                </Third>
             </Hori>

             <Hori>
                <Img>
                  <img src={two} alt='' style={{height:'100%',width:'100%'}} />
                </Img>
                <Third>
                  <Line />
                  <Content style={{margin:'0 0 0 1rem'}}>
                    2% WILL BE 
                    <div></div>DISTRIBUTED AMONG 
                    <div></div>$MOONS HOLDERS
                  </Content>
                 
                </Third>
             </Hori>

             <Hori>
                <Img>
                  <img src={two} alt='' style={{height:'100%',width:'100%'}} />
                </Img>
                <Third>
                  <Line />
                  <Content style={{margin:'0 0 0 1rem'}}>
                  Swapping $LUNC for 
                  <div/>$MOONS encompasses a 
                  <div/>reflection and burn 
                  <div/>mechanic which can be <div/>turned off in the future.
                  </Content>
                 
                </Third>
             </Hori>

             <Hori>
                <Img>
                  <img src={two} alt='' style={{height:'100%',width:'100%'}} />
                </Img>
                <Third>
                  <Line />
                  <Content style={{margin:'0 0 0 1rem'}}>
                  $MOONS will go towards<div/> a $LUNC buyback and 
                  <div />burn, while $MOONS will <div/>be accumulated and
                  <div/> distributed to all $MOONS <div/>holders. While the 
                  <div/>selling of $MOONS will go <div/>towards a burn and 
                  <div/>distribution to $MOONS <div/>holders alike.
                  </Content>
                 
                </Third>
             </Hori>

             <Hori>
                <Img>
                  <img src={two} alt='' style={{height:'100%',width:'100%'}} />
                </Img>
                <Third>
                  <Line />
                  <Content style={{margin:'0 0 0 1rem'}}>
                  THE DUAL BURNING<div/> MECHANISM WILL REDUCE 
                  <div/>THE CIRCULATING SUPPLY<div/> OF BOTH $LUNC AND $MOONS
                  </Content>
                 
                </Third>
             </Hori>
          </Width1>

        </Sec>
    )
}

export default Page2
