import React,{useState, useEffect, useRef } from 'react'
import './Navbar.css';
import logo from './logo.svg';
import mlogo from './logo.svg';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Burger, Menu } from './components';
import FocusLock from 'react-focus-lock';
import { Link, animateScroll as scroll } from "react-scroll";
import theme from "styled-theming";
import { useSelector, useDispatch } from "react-redux";
import git from '../../Page/Foot/github.png';
import med from '../../Page/Foot/medium.png'
import twi from './Twitter.png';
import { Link as RouterLink } from 'react-router-dom'

export const backgroundColor = theme("theme", {
  light: "#000000",
  dark: "#E5E5E5",
});  

export const Link1 = styled(RouterLink)`
  display: flex;
  margin: 0 0 0 8vw;
  align-items: center;
  text-decoration: none;
  color: #000;
  cursor: pointer;
 
 // background:red;
  font-family: 'Roboto Condensed', sans-serif;
  @-webkit-keyframes hvr-pulse {
    25% {
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    }
    75% {
      -webkit-transform: scale(0.9);
      transform: scale(0.9);
    }
  }
  @keyframes hvr-pulse {
    25% {
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    }
    75% {
      -webkit-transform: scale(0.9);
      transform: scale(0.9);
    }
  }
 
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    //box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  
  &:hover, &:focus, &:active {
    -webkit-animation-name: hvr-pulse;
    animation-name: hvr-pulse;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
  }
}

@media only screen and (max-width: 1600px) {
  margin: 0 0 0 1rem;
}

@media only screen and (max-width: 768px) {
  font-size: 1.8rem;
}


`;

const Link2 = styled(RouterLink)`
  display: flex;
  margin: 0;
  align-items: center;
  text-decoration: none;
  color: #FFF !important;
  cursor: pointer;
  

  @media only screen and (max-width: 768px) {
    
  }

`;

const Link2a = styled.div`
  display: flex;
  margin: 0;
  align-items: center;
  text-decoration: none;
  color: #FFF !important;
  cursor: pointer;
  

  @media only screen and (max-width: 768px) {
    
  }

`;

const Hr = styled.a`
display: flex;
margin: 0;
align-items: center;
text-decoration: none;
color: #FFF !important;
cursor: pointer;
@media only screen and (max-width: 768px) {
  
}
`

const Mobile = styled.div`
  display: none;
  @media only screen and (max-width: 1100px) {
    display: block;
  }`

const PC = styled.div`
display: block;
@media only screen and (max-width: 1100px) {
  display: none;
}`

export const Button = styled.button`
  
  border: none;
  //border-radius: 2rem;
 // padding: 0 1.5rem;
  background-color: transparent;
  font-size: 1rem;
  height: 3.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  border: none;
  position: relative;
  justify-content: center;
 // box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
`

export const IconContainer = styled.div`
  
  margin: 0 8vw 0 2rem;
  display: flex;
  align-items: center;
  @media only screen and (max-width: 1600px) {
    margin: 0 1rem 0 0;
  }
  @media only screen and (max-width: 1200px) {
    margin: 0 6rem 0 0;
  }
  @media only screen and (max-width: 768px) {
    display: none;
  }
 
`
export const Image = styled(motion.img)`
  cursor: pointer;
`
const RightContainer = styled.div`
  display: flex;
  //background: red;
  align-items: center;
`

const Navbar = () => {

  const darkThemeEnabled = useSelector((state) => state.preferences.darkThemeEnabled);


  const [open, setOpen] = useState(false);
  const node = useRef();
  const menuId = "main-menu";

const [navbar, setNavbar] = useState(false)

const changeBackground = () => {
    // console.log(window.scrollY)
    if (window.scrollY >= 50) {
      setNavbar(true)
    } else {
      setNavbar(false)
    }
  }

  useEffect(() => {
    changeBackground()
    // adding the event when scroll change background
    window.addEventListener("scroll", changeBackground)
  })  

    return (
        <nav className={navbar ?  "navActiveLight": "nav"} >

         
          <Link1 to="/" spy={true} smooth={true} ><img src={logo} alt="Logo" className={navbar ? "LogoActive" : "Logo"} /></Link1>
          
          {/* <RightContainer> */}
        
            <PC>
              <ul className="list">
                  <Link2 to="" spy={true} smooth={true} offset={-80}>ABOUT</Link2>
                  {/* <Link2 to="/earn" spy={true} smooth={true} offset={-80}>Earn</Link2>
                  <Link2 to="/donate" spy={true} smooth={true} offset={-80}>Donate</Link2> */ }
                  <Link2 to="/work" spy={true} smooth={true} offset={-80}>HOW IT WORKS</Link2>  
                  <a href="https://bit.ly/3mFouzo" target='_blank' >
                    <Link2a>WHITEPAPER</Link2a>
                  </a>
                  <Link2 to="/whitelist" spy={true} smooth={true} offset={-80}>WHITELIST</Link2> 
              </ul> 
            </PC>

            
            <div style={{display:'flex'}}>
              <IconContainer>
                <a href="https://twitter.com/savethemoon_io" target='_blank'>
                  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.1" fill-rule="evenodd" clip-rule="evenodd" d="M0 15.9417C0 7.13733 7.13733 0 15.9417 0C24.746 0 31.8833 7.13733 31.8833 15.9417C31.8833 24.746 24.746 31.8833 15.9417 31.8833C7.13733 31.8833 0 24.746 0 15.9417Z" fill="white"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.4643 12.9578L15.4978 13.5095L14.9402 13.4419C12.9108 13.183 11.1378 12.3049 9.63243 10.8302L8.89647 10.0984L8.7069 10.6388C8.30547 11.8434 8.56194 13.1155 9.39826 13.971C9.84429 14.4438 9.74394 14.5114 8.97453 14.2299C8.7069 14.1399 8.47274 14.0723 8.45043 14.1061C8.37238 14.1849 8.64 15.2093 8.85187 15.6146C9.14179 16.1775 9.73278 16.7291 10.3795 17.0556L10.9259 17.3145L10.2792 17.3258C9.65473 17.3258 9.63243 17.337 9.69933 17.5734C9.92235 18.3052 10.8033 19.0819 11.7845 19.4197L12.4759 19.6561L11.8738 20.0163C10.9817 20.5341 9.9335 20.8268 8.88532 20.8494C8.38353 20.8606 7.97095 20.9056 7.97095 20.9394C7.97095 21.052 9.33135 21.6824 10.1231 21.9301C12.4982 22.6618 15.3194 22.3466 17.438 21.097C18.9434 20.2077 20.4488 18.4403 21.1513 16.7291C21.5304 15.8173 21.9095 14.1511 21.9095 13.3519C21.9095 12.834 21.943 12.7665 22.5674 12.1473C22.9354 11.7871 23.2811 11.3931 23.348 11.2805C23.4595 11.0666 23.4484 11.0666 22.8797 11.258C21.9318 11.5957 21.798 11.5507 22.2664 11.0441C22.612 10.6838 23.0246 10.0309 23.0246 9.83952C23.0246 9.80575 22.8574 9.86203 22.6678 9.96335C22.4671 10.0759 22.021 10.2448 21.6865 10.3461L21.0844 10.5375L20.538 10.166C20.2369 9.96335 19.8132 9.7382 19.5902 9.67066C19.0215 9.51305 18.1517 9.53557 17.6387 9.71569C16.2449 10.2223 15.364 11.5281 15.4643 12.9578Z" fill="white"/>
                  </svg>
                </a>
                <a href="https://t.me/savethemoon_io" target='_blank'>
                  <div style={{margin:'0 0 0 1rem'}}>
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path opacity="0.1" fill-rule="evenodd" clip-rule="evenodd" d="M0 15.9417C0 7.13733 7.13733 0 15.9417 0C24.746 0 31.8833 7.13733 31.8833 15.9417C31.8833 24.746 24.746 31.8833 15.9417 31.8833C7.13733 31.8833 0 24.746 0 15.9417Z" fill="white"/>
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M8.10723 15.6876C12.4307 13.8039 15.3138 12.562 16.7563 11.962C20.875 10.2489 21.7308 9.95134 22.2886 9.94151C22.4113 9.93935 22.6856 9.96976 22.8633 10.1139C23.0134 10.2357 23.0547 10.4002 23.0744 10.5156C23.0942 10.631 23.1188 10.894 23.0992 11.0994C22.876 13.4445 21.9103 19.1355 21.4189 21.7621C21.211 22.8735 20.8017 23.2461 20.4054 23.2826C19.5441 23.3619 18.8901 22.7134 18.056 22.1666C16.7506 21.311 16.0132 20.7783 14.7462 19.9434C13.2819 18.9784 14.2311 18.4481 15.0656 17.5814C15.284 17.3545 19.0787 13.9029 19.1522 13.5898C19.1614 13.5507 19.1699 13.4047 19.0832 13.3276C18.9965 13.2505 18.8685 13.2769 18.7761 13.2979C18.6452 13.3276 16.5599 14.7058 12.5204 17.4327C11.9285 17.8391 11.3923 18.0372 10.912 18.0268C10.3825 18.0153 9.36383 17.7274 8.60659 17.4812C7.6778 17.1793 6.93962 17.0197 7.0039 16.5069C7.03738 16.2399 7.40516 15.9667 8.10723 15.6876Z" fill="white"/>
                    </svg>
                  </div>
                </a>
                <a href="https://medium.com/@savethemoon_io" target='_blank'>
                  <img src={med} alt="" style={{height:'2.4rem',width:'2.4rem',borderRadius:'50%',margin:'0 0 0 1rem'}} />
                </a>
                <a href="https://github.com/SaveTheMoon-io" target='_blank'>
                  <img src={git} alt="" style={{height:'2rem',width:'2rem',borderRadius:'50%',margin:'0 0 0 1rem'}} />
                </a>
                {/* <Button 
                    type="button"
                    whileHover={{ scale: 1.1,boxShadow:"0 0 25px #ff7b00"}}
                    whileTap={{ scale: 0.7 }}
                >
                  Download App  
                  <div style={{margin:'0.2rem 0 0 1rem'}}>
                    <svg  width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.13924 14L0 12.9781L6.72152 6.99999L0 1.0219L1.13924 0L9 6.99999L1.13924 14Z" fill="black"/>
                    </svg>
                  </div>

                </Button> */}
              </IconContainer> 

          
            <Mobile ref={node}>
              <FocusLock disabled={!open}>
                <Burger  open={open} setOpen={setOpen} aria-controls={menuId} />
                <Menu open={open} setOpen={setOpen} id={menuId} />
              </FocusLock>
            </Mobile>
          </div>

        {/* </RightContainer> */}

        </nav>   
    )
}

export default Navbar
